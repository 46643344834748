import React, { ReactElement } from 'react'

import Layout from '../../components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Hva er korsryggsmerter?</h1>

      <p>
        Korsryggsmerter er når en har smerter som typisk sitter rett over halebeinet. Smertene kan være lokale i midtre
        del av korsryggen eller de kan kjennes ut som et stort belte av smerter over hele eller bare en side av
        korsryggen. Korsryggsmerter kan også kjennes som utstråling i lårene eller som utstråling fra korsrygg og helt
        ned i foten. Noen kjenner også bare stråling eller nummenhet i foten. Strukturene som genererer smerte kan være
        muskel, sene, leddbånd, ledd eller skive.
      </p>

      <p>
        Korsryggsmerter er en av de vanligste årsakene til at folk oppsøker kiropraktor. Det vi si at dette er noe vi
        behandler daglig og har veldig god erfaring med. Hver behandling varer fra 20-30 minutt.
      </p>
    </Layout>
  )
}

export default Page
